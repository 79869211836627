/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  designPageTitle: 'UX Engineering & UI Design',
  introTitleDesign: 'We make ideas a reality',
  designIntroInfo: 'From the initial idea to a detailed concept to an interactive prototype - in collaboration with you, we create unique user experiences (UX) with modern user interfaces (UI), which increase customer satisfaction and strengthen your business.{br}{br}We digitize processes, products, services and interactions by designing user experiences that lay the foundation for your digital business. {br}{br}Our range of services covers the entire design and development process.{br}As a team player, we also work with in-house design/development departments or other service providers.',
  designIntroBtn: 'Get free consultation now',
  designStepsTitle: '<b>4 Phases</b> to the optimal design for your target group<b>:</b>',
  designStep1title: 'Analysis, Strategy & Conception',
  designStep1text:
    "Great concepts are created in dialog with all those involved. Together we develop good ideas, define concrete requirements and design suitable solutions. By focusing on the user and their challenges, we solve real problems in the authentic context of use.<br/><br/>'<ul class=\"list-bullets\"><li>Target group analysis (Persona mapping)</li><li>Market, trend and competition analysis</li><li>Requirements analysis (Contextual Inquiry & Use Cases)</li><li>User Journey Mapping</li><li>Ideation</li><li>Design Sprints</li><li>Information architecture</li><li>UX-Consulting & Training</li><li>Expert-Reviews</li><li>Focus group & user interviews</li><li>UX-Analysis & Conversion-Rate-Optimization</li></ul>'",
  designStep2title: 'User Interface & Interaction Design',
  designStep2text:
    "Pictures say more than 1,000 words - in order to get concrete impressions of the system early on in the project, we create interactive prototypes that serve for internal company presentations, user tests and as specifications for development.<br/><br/>'<ul class=\"list-bullets\"><li>Wireframes & Sketches</li><li>Moodboards & Web-Styleguides</li><li>Design System Definition & Pattern Libraries</li><li>Visual Design</li><li>Brand & Corporate Design</li><li>UX Writing</li><li>Interaction Design</li><li>High-Fidelity-Prototyping</li></ul>'",
  designStep3title: 'User Testing & Usability Optimization',
  designStep3text:
    "The use of prototypes and user tests enables rapid testing and validation of a wide variety of solution approaches. User & usability tests help you to make better decisions. Iteratively, the design can thus be optimized to develop a maximum user-friendly UI/UX design.<br/><br/>'<ul class=\"list-bullets\"><li>User Interviews</li><li>Usability Testing</li><li>A/B Testing</li><li>Remote User Testing</li><li>Heuristic Evaluation</li><li>Cognitive Walkthrough</li><li>Field Study</li></ul>'",
  designStep4title: 'Front-End Development & UX Engineering',
  designStep4text:
    "Component-based, dynamic, responsive. In the front-end, all results of the UI-UX process merge into a consistent, dynamic entity.<br/>UX Engineering combines design and development in interaction with the system architecture to create performant, flexible and optimally usable solutions.<br/><br/>'<ul class=\"list-bullets\"><li> User & System Requirements Engineering</li><li>Design System Development (Atomic Design)</li><li>Living Style Guide</li><li>Component Driven Development</li><li>Test Driven JS-Development</li></ul>'"
})

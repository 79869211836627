// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Headroom from 'react-headroom'
import { Container } from '../../../components/basic/Grid'

import { servicesList } from './../../Services/services'
import baseStyles from './ServicesNav.scss'

type Props = {
  ...StyleProps,
  services?: Array<string>
}

const ServicesNav = ({
  styles,
  services = [
    'consulting',
    'conceptStrategy',
    'design',
    'development',
    'systemIntegration',
    'systemEngineering',
    'appDevelopment',
    'hcmsIntegration'
  ]
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Headroom disableInlineStyles className={styles.root}>
      <Container className={styles.container} fluid>
        {services.map((item, i) => (
          <Link
            key={'service' + i}
            to={servicesList[item].localLink}
            className={styles.link}
            activeClassName={styles['is-active']}
            partiallyActive
          >
            <i className={classNames(styles.icon, servicesList[item].icon)} />
            <span className={styles.title}>{formatMessage(servicesList[item].title)}</span>
          </Link>
        ))}
      </Container>
    </Headroom>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicesNav)

// @flow

import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../../components/IntroTeaser'
import Services from '../../components/Services'
import ServicesNav from '../../components/ServicePages/ServicesNav'
import Section from '../../components/basic/Section'
import CaseTeasers from '../../components/CaseStudy/CaseTeasers'
import Clients from '../../components/Clients'
import messages from '../../components/ServicePages/UiUxDesign.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import pageTitle from '../../messages/pageTitles.lang'
import indexMessages from '../../components/HomePage/HomePage.lang'
import baseStyles from '../../components/ServicePages/ServicePages.scss'
import SEO from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { FormattedMessageSanitized } from '../../components/FormattedMessageSanitized'

const IntroImage = (
  <StaticImage src="../../images/services/subpages/ui-ux-design.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const Step1Image = (
  <StaticImage
    src="../../images/services/subpages/ui-ux-design-step01.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const Step2Image = (
  <StaticImage
    src="../../images/services/subpages/ui-ux-design-step02.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const Step3Image = (
  <StaticImage
    src="../../images/services/subpages/ui-ux-design-step03.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const Step4Image = (
  <StaticImage
    src="../../images/services/subpages/ui-ux-design-step04.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const ServicePage = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleDesign)}
        description={formatMessage(metaDescription.uiUxDesignDescription)}
        lang={locale}
      />
      <ServicesNav />
      <div className={styles.root}>
        <SubpageHeroTeaser title={formatMessage(messages.designPageTitle)} image={HeroTeaserImage} />
        <IntroTeaser
          title={formatMessage(messages.introTitleDesign)}
          copy={
            <div className={styles.text}>
              <p>
                <FormattedMessageSanitized {...messages.designIntroInfo} />
              </p>
            </div>
          }
          image={IntroImage}
          fullWidth
          btn={formatMessage(messages.designIntroBtn)}
          btnLink="/contact/"
        />
        <Section title={formatMessage(messages.designStepsTitle)} align="left" styles={styles.section}>
          <IntroTeaser
            title={formatMessage(messages.designStep1title)}
            titleCount="1."
            copy={
              <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: formatMessage(messages.designStep1text).replaceAll("'", '') }} />
              </div>
            }
            image={Step1Image}
            reversed
          />
          <IntroTeaser
            title={formatMessage(messages.designStep2title)}
            titleCount="2."
            copy={
              <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: formatMessage(messages.designStep2text).replaceAll("'", '') }} />
              </div>
            }
            image={Step2Image}
          />
          <IntroTeaser
            title={formatMessage(messages.designStep3title)}
            titleCount="3."
            copy={
              <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: formatMessage(messages.designStep3text).replaceAll("'", '') }} />
              </div>
            }
            image={Step3Image}
            reversed
          />
          <IntroTeaser
            title={formatMessage(messages.designStep4title)}
            titleCount="4."
            copy={
              <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: formatMessage(messages.designStep4text).replaceAll("'", '') }} />
              </div>
            }
            image={Step4Image}
          />
        </Section>
      </div>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Clients title={formatMessage(indexMessages.sectionTitleReferences)} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers
          list={['dertouristik', 'plattenplaner', 'lhh', 'etravel', 'aldiana', 'weekend', 'bedfinder']}
          allButton
        />
      </ScrollAnimation>
      <Services
        services={[
          'consulting',
          'conceptStrategy',
          'design',
          'development',
          'systemIntegration',
          'systemEngineering',
          'appDevelopment',
          'hcmsIntegration'
        ]}
      />
    </Layout>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicePage)
